<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="display: flex">
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="请输入文章标题"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" style="height: 34px!important;margin-left: 20px" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 新增文章
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="title" align="center" label="文章标题"/>
        <el-table-column align="center" label="缩略图">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.coverUrl"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="置顶" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.ifTop"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.kfqArticleId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增文章"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24 project_wrap"
          label-position="left"
      >
        <el-form-item prop="title" label="文章标题">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入文章标题"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="缩略图" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <!--              上传图片的最佳尺寸：4:9，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M-->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文章内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm.details"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{ TiLength }}/10000</span>
        </el-form-item>
        <el-form-item label="显示状态" prop="isDistribution">
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item label="置顶" label-width="130px" prop="isDistribution">-->
<!--          <el-radio-group v-model="ruleForm.zhiding">-->
<!--            <el-radio :label="0">是</el-radio>-->
<!--            <el-radio :label="1">否</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请输入内容'

import {addKfqArticle,selectKfqArticlePageList,upKfqArticle,delKfqArticle,upKfqArticleTop} from "@/api/serviceDevelopmentZone";

export default {
  name: "doubleCarbon",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TiLength: 0,
      editorOption: quillConfig,
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        details: "",
        coverImg: "",
        status:0,
        zhiding:0,
      },
      fileList: [],
      dialogVisible: false,
      rules: {
        title: [{required: true, message: "请输入文章标题", trigger: "blur"}],
        details: [{required: true, message: "请输入内容", trigger: "blur"}],
        coverImg: [{required: true, message: "请上传图片"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{label: "全部", value: 1}],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate: 1,
      kfqArticleId: "",
      srcList: [],
      ossUrl: this.$store.state.ossUrl,
      typeList: []
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    ready() {
      Quill.register({'formats/lineHeight': lineHeightStyle}, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    async queryPage(payload) {
      let data = {
        type:0,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectKfqArticlePageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].coverUrl) {
            a.push(list[i].coverUrl)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].ifTop == 0) {
            list[i].ifTop = true
          }else {
            list[i].ifTop = false
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      this.ruleForm.title = data.title;
      this.ruleForm.details = data.content;
      this.ruleForm.status = data.status;
      // this.ruleForm.ifTop = data.ifTop;
      this.kfqArticleId = data.kfqArticleId
      this.ruleForm.coverImg = data.coverUrl
      this.fileList = [{ url: data.coverUrl, uid: 1 }];
    },
    //删除弹窗
    async del(val) {
      try {
        await delKfqArticle({kfqArticleId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        title: this.filterName,
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      let data = {
        title: this.filterName,
      };
      this.currentPage = val;
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      let data = {
        title: this.filterName,
      };
      this.pageSize = val;
      this.queryPage(data);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        details: "",
        coverImg: "",
        status: 0,
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              title: this.ruleForm.title,
              coverUrl: this.ruleForm.coverImg,
              content: this.ruleForm.details,
              status: this.ruleForm.status,
              type:0,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              data.ifTop = 1
              await addKfqArticle(data);
            } else {
              data.kfqArticleId = this.kfqArticleId
              await upKfqArticle(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = "";
    },

    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          kfqArticleId:row.kfqArticleId,
          associationId: localStorage.getItem("associationId"),
        }
        const result = await upKfqArticleTop(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .project_wrap .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .project_wrap .el-upload--text {
  width: 150px;
  height: 150px;
}

.el-icon-paperclip {
  margin-right: 10px;
}
</style>





